import React from 'react';

const TitleHeader = ({ children, title }) => (
    <div id="title-header-container">
        <header id="title-header">
            <h1 id="title-header__title">{title || children}</h1>
        </header>
    </div>
)

export default TitleHeader;