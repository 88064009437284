import React from 'react';

import Img from 'gatsby-image';

import Tag from './tag';

const ProductCard = ({ img, title, inches, categories, productName }) => (
    <li className="product-card">
        {img.fluid ?
            <Img
                fluid={img.fluid}
                objectFit="contain"
                imgStyle={{
                    objectFit: "contain"
                }}
                style={{
                    height: "8em"
                }}
            />
            :
            <img className="product-card__img" src={img.src} alt={img.alt} />}
        <h3 className="product-card__title">{title}</h3>
        {productName && <div className="product-card__product-name">{productName}</div>}
        <ul className="product-card__tag-container">
            {categories.map(category => (
                <Tag key={`${title}-${category}`} className="product-card__tag">{category}</Tag>
            ))}
        </ul>
    </li>
);

export default ProductCard;

