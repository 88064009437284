import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';
import TitleHeader from '../layout/title-header';

import ProductCard from '../components/product-card';
import SEO from '../components/seo';
import { Link } from 'gatsby';

import ViewtecBackground from '../images/viewtec-pattern.svg';

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [props.data.prismicProductsPage.data.all_category]
    }
  }
  onCheck = (category) => {
    const checked = this.state.selectedCategories.findIndex(c => c === category);
    if (checked !== -1) {
      this.setState(state => (
        { selectedCategories: state.selectedCategories.slice(0, checked).concat(state.selectedCategories.slice(checked + 1)) }
      ));
    } else {
      if (category !== this.props.data.prismicProductsPage.data.all_category) {
        this.setState(state => (
          { selectedCategories: [category].concat(state.selectedCategories.filter(c => c !== this.props.data.prismicProductsPage.data.all_category)) }
        ))
      } else {
        this.setState({ selectedCategories: [this.props.data.prismicProductsPage.data.all_category] });
      }
    }
  }
  render() {
    const pageData = this.props.data.prismicProductsPage;
    this.props.data.allPrismicProducts.nodes.forEach(
      ({ data }) => {
        data.categories = data.categories.filter(({ category }) => category);
        console.log(data.categories);
      })
    const categories = [pageData.data.all_category]
      .concat(this.props.data.allPrismicProductCategory.nodes.map(node => node.data.name));
    return (
      <Layout lang={pageData.lang}>
        <SEO
          title={pageData.data.title}
          keywords={pageData.data.keywords.map(({ keyword }) => keyword)}
          lang={pageData.lang}
        />
        <TitleHeader title={pageData.data.title} background={ViewtecBackground} />
        <main id="products-page" className="responsive-paddings">
          <ul id="category-selector" className="remove-list-styles">
            {categories.map(category =>
              <li key={category}>
                <CheckBox handleClick={this.onCheck}
                  active={this.state.selectedCategories.includes(category)}
                  text={category} />
              </li>)}
          </ul>
          <ul id="products-grid">
            {
              this.props.data.allPrismicProducts.nodes
                //Filter products depending of state category (inclusive). Show all in state has all.
                .filter(({ data }) => this.state.selectedCategories.includes(pageData.data.all_category) || data.categories.some(({ category }) => this.state.selectedCategories.includes(category.document[0].data.name)))
                //map the data from Filtered Products and creates an array of <ProductCard/>
                .map(({ fields, data }) => (
                  <Link
                    className="product-card-container float"
                    to={fields.slug}
                    lang={pageData.lang}
                    key={`product-card-${data.product_name.text}`}
                    aria-label={`Link to product ${data.product_name.text}`}
                  >
                    <ProductCard
                      img={{
                        src: data.image_gallery[0].image.url,
                        alt: data.image_gallery[0].image.alt,
                        fluid: data.image_gallery[0].image.localFile && data.image_gallery[0].image.localFile.childImageSharp.fluid
                      }}
                      title={data.display_name.text}
                      productName={data.product_name.text}
                      inches={data.panel_size}
                      categories={data.categories.map(({ category }) => category.document[0].data.name)}
                      lang={pageData.lang}
                    />
                  </Link>
                ))
            }
          </ul>
        </main>
      </Layout>
    )
  }
}

const CheckBox = ({ text, handleClick, active }) => (
  <button className={`remove-button-styles check-box${active ? ' check-box--active' : ''}`} onClick={() => handleClick(text)}>
    <div className="check-box__box" />
    <span className="check-box__text">{text}</span>
  </button>
)

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    prismicProductsPage(fields: { slug: { eq: $slug } } ) {
      lang
      data {
        title
        keywords {
          keyword
        }
        all_category
      }
    }
    allPrismicProductCategory(filter: {lang: {eq: $lang}}) {
      nodes {
          data {
              name
          }
      }
    }
    allPrismicProducts(
      filter: {lang: {eq: $lang}},
      sort:{
        fields: [data___panel_size],
        order: ASC
    }) {
      nodes {
        fields {
          slug
        }
        data {
          product_name {
            text
          }
          display_name {
            text
          }
          panel_size
          categories {
            category {
              document {
                data {
                  name
                }
              }
            }
          }
          image_gallery {
            image {
              url
              alt
              localFile {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`;